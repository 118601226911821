import { defineStore } from "pinia";
import type { UseFetchOptions } from "@vueuse/core";
import { format } from "date-fns";
import { flattenChildrenWithExclusion, restructureDataForTable } from "~/utils";

const { BASE_URL } = useRuntimeConfig().public;
const auth = useCookie<AuthResponse>("auth");

const formatDateRange = (dateRange?: DateRangeType) =>
  dateRange
    ? {
        start: format(new Date(dateRange.start), "yyyy-MM-dd"),
        end: format(new Date(dateRange.end), "yyyy-MM-dd"),
      }
    : undefined;

export const useReportsStore = defineStore("reportsStore", {
  state: (): ReportsResponseType => ({
    filters: {
      search: "",
      searchResults: [],
      brands: [],
      multiBrands: {},
      multiBrandsSelection: [],
      campaigns: [],
      platforms: [],
      selectedFirstDate: undefined,
      selectedSecondDate: undefined,
      gender: "",
      pax: [],
      cards: [],
      dataReports: [],
      metrics: {},
      tables: {
        noComparison: [],
        noComparisonCampaigns: [],
        comparison: [],
        comparisonCampaigns: [],
      },
      performance: {
        filters: {
          type: "impressions",
          period: "months",
        },
        loading: false,
      },
      loading: true,
      error: false,
    },
    audienceSummary: {},
    dashboardOrder: [],
    savedDashboardOrder: [],
  }),
  getters: {
    getDataReports(state) {
      return state.filters.dataReports;
    },
    getReportsBodyRequest(state) {
      return {
        brands: state.filters.brands.length > 0 ? state.filters.brands : [],
        campaigns: state.filters.campaigns.length > 0 ? state.filters.campaigns : [],
        platforms: state.filters.platforms.length > 0 ? state.filters.platforms : [],
        time_periods:
          !state.filters.selectedFirstDate && !state.filters.selectedSecondDate
            ? undefined
            : [
                state.filters.selectedFirstDate && {
                  start_date: state.filters.selectedFirstDate.start,
                  end_date: state.filters.selectedFirstDate.end,
                },
                state.filters.selectedSecondDate && {
                  start_date: state.filters.selectedSecondDate.start,
                  end_date: state.filters.selectedSecondDate.end,
                },
              ].filter((item) => item !== undefined),
        gender: state.filters.gender.length > 0 ? state.filters.gender : "",
      };
    },
    getReportsBodyRequestGender(state) {
      return {
        brands: state.filters.brands.length > 0 ? state.filters.brands : [],
        campaigns: state.filters.campaigns.length > 0 ? state.filters.campaigns : [],
        platforms: state.filters.platforms.length > 0 ? state.filters.platforms : [],
        time_periods:
          !state.filters.selectedFirstDate && !state.filters.selectedSecondDate
            ? undefined
            : [
                state.filters.selectedFirstDate && {
                  start_date: state.filters.selectedFirstDate.start,
                  end_date: state.filters.selectedFirstDate.end,
                },
                state.filters.selectedSecondDate && {
                  start_date: state.filters.selectedSecondDate.start,
                  end_date: state.filters.selectedSecondDate.end,
                },
              ].filter((item) => item !== undefined),
        gender: state.filters.gender.length > 0 ? [state.filters.gender] : "",
      };
    },
    getReportsBodyRequestMultiBrands(state) {
      return {
        brands: state.filters.brands.length > 0 ? state.filters.brands : [],
        campaigns: state.filters.campaigns.length > 0 ? state.filters.campaigns : [],
        platforms: state.filters.platforms.length > 0 ? state.filters.platforms : [],
        reports: true,
        time_periods:
          !state.filters.selectedFirstDate && !state.filters.selectedSecondDate
            ? undefined
            : [
                state.filters.selectedFirstDate && {
                  start_date: state.filters.selectedFirstDate.start,
                  end_date: state.filters.selectedFirstDate.end,
                },
                state.filters.selectedSecondDate && {
                  start_date: state.filters.selectedSecondDate.start,
                  end_date: state.filters.selectedSecondDate.end,
                },
              ].filter((item) => item !== undefined),
        gender: state.filters.gender.length > 0 ? state.filters.gender : "",
      };
    },
    getBodyRequestMultiBrands(state) {
      return {
        brands: state.filters.multiBrandsSelection.length > 0 ? state.filters.multiBrandsSelection : [],
        campaigns: state.filters.campaigns.length > 0 ? state.filters.campaigns : [],
        platforms: state.filters.platforms.length > 0 ? state.filters.platforms : [],
        reports: true,
        time_periods:
          !state.filters.selectedFirstDate && !state.filters.selectedSecondDate
            ? undefined
            : [
                state.filters.selectedFirstDate && {
                  start_date: state.filters.selectedFirstDate.start,
                  end_date: state.filters.selectedFirstDate.end,
                },
                state.filters.selectedSecondDate && {
                  start_date: state.filters.selectedSecondDate.start,
                  end_date: state.filters.selectedSecondDate.end,
                },
              ].filter((item) => item !== undefined),
      };
    },
  },
  actions: {
    // Brand actions
    deleteBrand(id: string) {
      this.filters.brands = this.filters.brands.filter((brand) => brand !== id);
      if (this.filters.campaigns.length > 0) {
        this.filters.campaigns = [];
      }
    },
    // Campaign actions
    deleteCampaign(id: string) {
      this.filters.campaigns = this.filters.campaigns.filter((brand) => brand !== id);
      if (this.filters.brands.includes("multibrand")) {
        this.filters.brands = this.filters.brands.filter((brand) => brand !== "multibrand");
      }
    },
    deleteGender() {
      this.filters.gender = "";
    },
    resetCampaigns() {
      this.filters.campaigns = [];
      this.filters.platforms = [];
      this.filters.selectedFirstDate = undefined;
      this.filters.selectedSecondDate = undefined;
    },
    // Platform actions
    deletePlatform(id: string) {
      this.filters.platforms = this.filters.platforms.filter((brand) => brand !== id);
    },
    resetPlatforms() {
      this.filters.platforms = [];
      this.filters.selectedFirstDate = undefined;
      this.filters.selectedSecondDate = undefined;
    },
    // Date Range actions
    deleteFirstSelectedDateRange() {
      this.filters.selectedFirstDate = undefined;
    },
    deleteSecondSelectedDateRange() {
      this.filters.selectedSecondDate = undefined;
    },
    resetAllSelectedDates() {
      this.filters.selectedFirstDate = undefined;
      this.filters.selectedSecondDate = undefined;
    },
    resetMultiBrands() {
      this.filters.multiBrandsSelection = [];
    },
    // Overall actions
    setFilters(
      brands: string[],
      campaigns: string[],
      platforms: string[],
      firstDate: DateRangeType | undefined,
      secondDate: DateRangeType | undefined,
      gender: string,
    ) {
      this.filters.brands = brands;
      this.filters.campaigns = campaigns;
      this.filters.platforms = platforms;
      this.filters.selectedFirstDate = formatDateRange(firstDate);
      this.filters.selectedSecondDate = formatDateRange(secondDate);
      this.filters.gender = gender;
    },
    setDashboardOrder(dashboardOrder: DashboardItemType[]) {
      this.dashboardOrder = dashboardOrder.map((item) => {
        return {
          component: item.component,
          report: item.data !== undefined ? item.data.name : "CampaignPerformance",
          order: item.order,
        };
      });
    },
    setMultiBrandsSelection(multiBrandsSelection: string[]) {
      this.filters.multiBrandsSelection = multiBrandsSelection;
    },
    resetAllFilters() {
      this.filters.brands = [];
      this.filters.campaigns = [];
      this.filters.platforms = [];
      this.filters.multiBrandsSelection = [];
      this.filters.selectedFirstDate = undefined;
      this.filters.selectedSecondDate = undefined;
      this.filters.gender = "";
    },
    resetSearchResults() {
      this.filters.searchResults = [];
    },
    resetInfo() {
      this.filters.cards = [];
      if (this.filters.tables.noComparison.length > 0) {
        this.filters.tables.noComparison = [];
      }
      if (this.filters.tables.noComparisonCampaigns.length > 0) {
        this.filters.tables.noComparisonCampaigns = [];
      }
      if (this.filters.tables.comparison.length > 0) {
        this.filters.tables.comparison = [];
      }
      if (this.filters.tables.comparisonCampaigns.length > 0) {
        this.filters.tables.comparisonCampaigns = [];
      }
      this.filters.dataReports = [];
      this.audienceSummary = {};
      this.filters.performance.data = undefined;
    },
    replaceUuids(genderData: GenderResponse) {
      const sumUuids = genderData.reduce((accumulator: any, currentValue: { uuids: any }) => {
        return accumulator + currentValue.uuids;
      }, 0);
      for (let i = 0; i < genderData.length; i++) {
        if (i < this.filters.cards.length - 1) {
          (this.filters.cards[i] as CardsResponse).uuids = genderData[i].uuids;
        }
      }
      (this.filters.cards[this.filters.cards.length - 1] as AllCardsResponse).sum_uuids = sumUuids;
    },
    setTypePerformanceFilter(value: string) {
      this.filters.performance.filters.type = value;
    },
    async setTypePerformancePeriod(value: string) {
      this.filters.performance.filters.period = value;
      await this.getPerformanceData();
    },
    // Request actions
    async getCardsAndTables() {
      this.filters.loading = true;
      if (this.filters.error) this.filters.error = false;
      this.resetInfo();
      const requestConfig = {
        headers: {
          Authorization: `Bearer ${auth.value.access}`,
        },
        body: this.getReportsBodyRequest,
        method: "POST",
      };
      const requestConfigGender = {
        headers: {
          Authorization: `Bearer ${auth.value.access}`,
        },
        body: this.getReportsBodyRequestGender,
        method: "POST",
      };
      const requestConfigMulti = {
        headers: {
          Authorization: `Bearer ${auth.value.access}`,
        },
        body: this.getReportsBodyRequestMultiBrands,
        method: "POST",
      };
      const requestSummary = {
        headers: {
          Authorization: `Bearer ${auth.value.access}`,
        },
        body: {
          brand: this.filters.brands[0],
        },
        method: "POST",
      };
      const requestOrder = {
        headers: {
          Authorization: `Bearer ${auth.value.access}`,
        },
        method: "GET",
      };
      const {
        campaigns,
        brands,
        platforms,
        gender,
      }: { campaigns: string[]; brands: string[]; platforms: string[]; gender: string } = this.getReportsBodyRequest;

      const [cardsAndTables, dataReports, cpot, multiBrands] = await Promise.all([
        (() => {
          return useFetch<(CardsResponse | AllCardsResponse)[]>(
            `${BASE_URL}/api/v1/reports/performance_metrics/`,
            requestConfig as UseFetchOptions,
          );
        })(),
        useFetch<DataReportsType>(`${BASE_URL}/api/v1/reports/charts_values/`, requestConfig as UseFetchOptions),
        campaigns.length === 0
          ? useFetch<PerformanceResponseType>(
              `${BASE_URL}/api/v2/metrics/monthly_cpot/brands/`,
              requestConfig as UseFetchOptions,
            )
          : useFetch<PerformanceResponseType>(
              `${BASE_URL}/api/v2/metrics/monthly_cpot/brands/${brands[0]}/campaigns/`,
              requestConfig as UseFetchOptions,
            ),
        (() => {
          if (brands.includes("multibrand") && Object.keys(this.filters.multiBrands).length === 0) {
            return useFetch<DropdownSelectionResponse>(
              `${BASE_URL}/api/v2/brands/`,
              requestConfigMulti as UseFetchOptions,
            );
          }
        })(),
      ]);
      if (cardsAndTables?.error.value) {
        this.filters.error = true;
      } else if (cardsAndTables?.data.value) {
        this.filters.cards = cardsAndTables.data.value;
        if (campaigns.length === 0 && (brands.length === 1 || platforms.length === 1)) {
          this.filters.tables.noComparison = (cardsAndTables.data.value[0] as CardsResponse).children
            ? (cardsAndTables.data.value[0] as CardsResponse).children.filter(
                (child) => child.child_id !== "Remaining Data",
              )
            : [];
        } else if (campaigns.length === 1 && (brands.length === 1 || platforms.length === 1)) {
          this.filters.tables.noComparisonCampaigns = (cardsAndTables.data.value[0] as CardsResponse).children ?? [];
        } else if (campaigns.length === 0 && (brands.length === 2 || platforms.length === 2)) {
          this.filters.tables.comparison = flattenChildrenWithExclusion(cardsAndTables.data.value as CardsResponse[]);
        } else if (campaigns.length === 2 && (brands.length <= 1 || platforms.length <= 1)) {
          const firstChildren = (cardsAndTables.data.value[0] as CardsResponse).children ?? [];
          const secondChildren = (cardsAndTables.data.value[1] as CardsResponse).children ?? [];
          this.filters.tables.comparisonCampaigns = restructureDataForTable(firstChildren, secondChildren);
        }
        if (gender !== "") {
          const genderData = await useFetch<GenderResponse>(
            `${BASE_URL}/api/v1/reports/uuids_gender/`,
            requestConfigGender as UseFetchOptions,
          );
          if (genderData?.data.value && genderData?.data.value.length === 1) {
            (this.filters.cards[0] as CardsResponse).uuids = genderData.data.value[0].uuids;
          } else if (genderData.data.value && genderData.data.value.length > 1) {
            this.replaceUuids(genderData.data.value);
          }
        }
      }
      if (dataReports.data.value) {
        const [orderDashboard] = await Promise.all([
          useFetch<DashboardOrderResponse>(
            `${BASE_URL}/api/v2/reports/custom_layout/`,
            requestOrder as UseFetchOptions,
          ),
        ]);
        if (orderDashboard.error.value) {
          this.savedDashboardOrder = [];
        } else if (orderDashboard.data.value) {
          this.savedDashboardOrder = orderDashboard.data.value.results.charts_layout;
        }
        this.filters.dataReports = dataReports.data.value.results;
      }
      if (cpot.data.value) {
        this.filters.performance.data = cpot.data.value.results;
      }
      if (multiBrands?.data.value) {
        this.filters.multiBrands = multiBrands.data.value.results;
      }
      if (brands.length === 1) {
        const summary = await useFetch<GenericResponse>(
          `${BASE_URL}/api/v2/audience_summary/`,
          requestSummary as UseFetchOptions,
        );
        if (summary.data.value) {
          this.audienceSummary = summary.data.value.results;
        }
      }
      this.filters.loading = false;
    },
    async sendDashboardOrder() {
      const requestConfig = {
        headers: {
          Authorization: `Bearer ${auth.value.access}`,
        },
        body: this.dashboardOrder,
        method: "POST",
      };
      await Promise.all([
        useFetch<(CardsResponse | AllCardsResponse)[]>(
          `${BASE_URL}/api/v2/reports/custom_layout/`,
          requestConfig as UseFetchOptions,
        ),
      ]);
    },
    async getCardsAndTablesMultiBrands() {
      this.filters.loading = true;
      if (this.filters.error) this.filters.error = false;
      this.resetInfo();
      const requestConfig = {
        headers: {
          Authorization: `Bearer ${auth.value.access}`,
        },
        body: this.getBodyRequestMultiBrands,
        method: "POST",
      };

      const { campaigns, brands, platforms }: { campaigns: string[]; brands: string[]; platforms: string[] } =
        this.getBodyRequestMultiBrands;
      const [cardsAndTables, dataReports, cpot] = await Promise.all([
        useFetch<(CardsResponse | AllCardsResponse)[]>(
          `${BASE_URL}/api/v1/reports/performance_metrics/`,
          requestConfig as UseFetchOptions,
        ),
        useFetch<DataReportsType>(`${BASE_URL}/api/v1/reports/charts_values/`, requestConfig as UseFetchOptions),
        useFetch<PerformanceResponseType>(
          `${BASE_URL}/api/v2/metrics/monthly_cpot/multibrands/campaigns/${campaigns}/`,
          requestConfig as UseFetchOptions,
        ),
      ]);
      if (cardsAndTables.data.value) {
        this.filters.cards = cardsAndTables.data.value;
        this.filters.error = false;
        this.filters.loading = false;
        if (campaigns.length === 0 && (brands.length === 1 || platforms.length === 1)) {
          this.filters.tables.noComparison = (cardsAndTables.data.value[0] as CardsResponse).children
            ? (cardsAndTables.data.value[0] as CardsResponse).children.filter(
                (child) => child.child_id !== "Remaining Data",
              )
            : [];
        } else if (campaigns.length === 1 && (brands.length === 1 || platforms.length === 1)) {
          this.filters.tables.noComparisonCampaigns = (cardsAndTables.data.value[0] as CardsResponse).children ?? [];
        } else if (campaigns.length === 1 && (brands.length <= 2 || platforms.length <= 1)) {
          const firstChildren = (cardsAndTables.data.value[0] as CardsResponse).children ?? [];
          const secondChildren = (cardsAndTables.data.value[1] as CardsResponse).children ?? [];
          const thirdChildren = (cardsAndTables.data.value[2] as CardsResponse).children ?? [];
          this.filters.tables.comparisonCampaigns = restructureDataForTable(
            firstChildren,
            secondChildren,
            thirdChildren,
          );
        }
      }
      if (dataReports.data.value) {
        this.filters.dataReports = dataReports.data.value.results;
      }
      if (cpot.data.value) {
        this.filters.performance.data = cpot.data.value.results;
      }
    },
    async getSearchCampaigns(searchTerm: string) {
      this.filters.search = "";
      this.filters.search = searchTerm;
      const requestSearchBody = {
        headers: {
          Authorization: `Bearer ${auth.value.access}`,
        },
        body: {
          filter_text: searchTerm,
        },
        method: "POST",
      };
      const [search] = await Promise.all([
        useFetch<SearchResponse[]>(`${BASE_URL}/api/v1/reports/search_box/`, requestSearchBody as UseFetchOptions),
      ]);
      if (search.data.value) {
        this.filters.searchResults = search.data.value;
      }
    },
    async getPerformanceData() {
      const period = this.filters.performance.filters.period;
      const brands = this.filters.brands;
      const campaigns = this.filters.campaigns;
      const requestConfigBrands = {
        headers: {
          Authorization: `Bearer ${auth.value.access}`,
        },
        body: {
          brands: this.filters.brands,
          platforms: this.filters.platforms.length > 0 ? this.filters.platforms : [],
          time_periods:
            !this.filters.selectedFirstDate && !this.filters.selectedSecondDate
              ? undefined
              : [
                  this.filters.selectedFirstDate && {
                    start_date: this.filters.selectedFirstDate.start,
                    end_date: this.filters.selectedFirstDate.end,
                  },
                  this.filters.selectedSecondDate && {
                    start_date: this.filters.selectedSecondDate.start,
                    end_date: this.filters.selectedSecondDate.end,
                  },
                ].filter((item) => item !== undefined),
        },
        method: "POST",
      };
      const requestConfigMultiBrands = {
        headers: {
          Authorization: `Bearer ${auth.value.access}`,
        },
        body: {
          brands: this.filters.multiBrandsSelection,
          platforms: this.filters.platforms.length > 0 ? this.filters.platforms : [],
          time_periods:
            !this.filters.selectedFirstDate && !this.filters.selectedSecondDate
              ? undefined
              : [
                  this.filters.selectedFirstDate && {
                    start_date: this.filters.selectedFirstDate.start,
                    end_date: this.filters.selectedFirstDate.end,
                  },
                  this.filters.selectedSecondDate && {
                    start_date: this.filters.selectedSecondDate.start,
                    end_date: this.filters.selectedSecondDate.end,
                  },
                ].filter((item) => item !== undefined),
        },
        method: "POST",
      };
      const requestConfigCampaigns = {
        headers: {
          Authorization: `Bearer ${auth.value.access}`,
        },
        body: {
          campaigns: this.filters.campaigns,
          platforms: this.filters.platforms.length > 0 ? this.filters.platforms : [],
          time_periods:
            !this.filters.selectedFirstDate && !this.filters.selectedSecondDate
              ? undefined
              : [
                  this.filters.selectedFirstDate && {
                    start_date: this.filters.selectedFirstDate.start,
                    end_date: this.filters.selectedFirstDate.end,
                  },
                  this.filters.selectedSecondDate && {
                    start_date: this.filters.selectedSecondDate.start,
                    end_date: this.filters.selectedSecondDate.end,
                  },
                ].filter((item) => item !== undefined),
        },
        method: "POST",
      };
      if (period === "weeks") {
        // Brand
        this.filters.performance.loading = true;
        if (
          (campaigns.length === 0 && this.filters.brands.length <= 2) ||
          (campaigns.length === 0 && this.filters.brands.length <= 2 && this.filters.multiBrandsSelection.length === 0)
        ) {
          const [weekly] = await Promise.all([
            useFetch<PerformanceResponseType>(
              `${BASE_URL}/api/v2/metrics/weekly_cpot/brands/`,
              requestConfigBrands as UseFetchOptions,
            ),
          ]);
          if (weekly.data.value) {
            this.filters.performance.data = weekly.data.value.results;
            this.filters.performance.loading = false;
          }
        } else if (this.filters.multiBrandsSelection.length > 0) {
          const [weeklyMulti] = await Promise.all([
            useFetch<PerformanceResponseType>(
              `${BASE_URL}/api/v2/metrics/weekly_cpot/multibrands/campaigns/${campaigns}/`,
              requestConfigMultiBrands as UseFetchOptions,
            ),
          ]);
          if (weeklyMulti.data.value) {
            this.filters.performance.data = weeklyMulti.data.value.results;
          }
        }
        // Campaigns
        if (
          campaigns.length >= 1 &&
          this.filters.brands.length === 1 &&
          this.filters.multiBrandsSelection.length === 0
        ) {
          const [weekly] = await Promise.all([
            useFetch<PerformanceResponseType>(
              `${BASE_URL}/api/v2/metrics/weekly_cpot/brands/${brands}/campaigns/`,
              requestConfigCampaigns as UseFetchOptions,
            ),
          ]);
          if (weekly.data.value) {
            this.filters.performance.data = weekly.data.value.results;
            this.filters.performance.loading = false;
          }
        }
      }
      // Months
      if (period === "months") {
        // Brands
        this.filters.performance.loading = true;
        if (
          (campaigns.length === 0 && this.filters.brands.length <= 2) ||
          (campaigns.length === 0 && this.filters.brands.length <= 2 && this.filters.multiBrandsSelection.length === 0)
        ) {
          const [monthly] = await Promise.all([
            useFetch<PerformanceResponseType>(
              `${BASE_URL}/api/v2/metrics/monthly_cpot/brands/`,
              requestConfigBrands as UseFetchOptions,
            ),
          ]);
          if (monthly.data.value) {
            this.filters.performance.data = monthly.data.value.results;
            this.filters.performance.loading = false;
          }
        } else if (this.filters.multiBrandsSelection.length > 0) {
          const [weeklyMulti] = await Promise.all([
            useFetch<PerformanceResponseType>(
              `${BASE_URL}/api/v2/metrics/monthly_cpot/multibrands/campaigns/${campaigns}/`,
              requestConfigMultiBrands as UseFetchOptions,
            ),
          ]);
          if (weeklyMulti.data.value) {
            this.filters.performance.data = weeklyMulti.data.value.results;
            this.filters.performance.loading = false;
          }
        }
        // Campaigns
        if (
          campaigns.length >= 1 &&
          this.filters.brands.length === 1 &&
          this.filters.multiBrandsSelection.length === 0
        ) {
          const [monthly] = await Promise.all([
            useFetch<PerformanceResponseType>(
              `${BASE_URL}/api/v2/metrics/monthly_cpot/brands/${brands}/campaigns/`,
              requestConfigCampaigns as UseFetchOptions,
            ),
          ]);
          if (monthly.data.value) {
            this.filters.performance.data = monthly.data.value.results;
            this.filters.performance.loading = false;
          }
        }
      }
    },
  },
});
